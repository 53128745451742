@import "./../../styles/responsive";
@import "./../../styles/colors";


// Navigation
.project-container{
   
    .navigation-container{
       
    }
}

.page-header-container{
    width: 100%;

    .bg-img{
        max-width: 1600px;
        margin: auto;
        position: relative;

        -webkit-background-size: contain!important;
        -moz-background-size: contain!important;
        -o-background-size: contain!important;
        background-size: contain!important;
        background-position: right!important;

        @include resp(desktop-s) { 
            -webkit-background-size: auto 80%!important;
            -moz-background-size: auto 80%!important;
            -o-background-size: auto 80%!important;
            background-size: auto 80%!important;
            background-position: center!important;
        }
        
        .overlay{
            position: absolute;
            top: 0px;
            right: 0px;
            left: 0px;
            bottom: 0px;
        }
    }

    .page-header-inner-container{

        padding: 3rem 2rem 0;

        @include resp(desktop-xs) { 
            padding: 4rem 2rem 0;
        }
        @include resp(desktop-s) { 
            padding: 4rem 2rem 0;
            .left-container, .right-container{
                width: 50%;
            }
        }
        @include resp(desktop-m) { 
            padding: 4rem 2rem 0;
        }
        @include resp(desktop-l) {
            padding: 4rem 2rem 0;
        }
        @include resp(desktop-xl) {
            padding: 4rem 0 0;
        }

        .header{
            font-size: 5rem;
            line-height: 5rem;
        }
        @include resp(desktop-xl) {
            .header{
                font-size: 5rem;
                line-height: 5rem;
            }
        }
    }

}





