@import "./../../styles/colors";
@import "./../../styles/responsive";

.footer-container{
    width: 100%;
    *{
        color: white;
    }
    
    background-color: $color-dark-navy;
    
    .footer-content-container{
        padding: 4rem 0rem;
        margin: 0 4rem;
        text-align: center;
        @include resp(desktop-s) {
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        @include resp(desktop-xl) { 
            margin: auto;
            max-width: 1600px;
        }

        
        
        .left-container{

        }
        .right-container{

        }
    }
}


// Content specs
.footer-container .footer-content-container{
    .left-container.caption{
        .h5{
            font-family: 'Gilroy';
        }
        .subtitle{
            margin: 0.5rem 0 0 0;
            color: $color-dark-navy-50;
        }
    }
    .right-container.media{
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        @include resp(desktop-s) {
            justify-content: flex-end;
        }
        
        gap: 2rem;
    }
}