.button {
  border: 1px solid rgba(255, 255, 255, 0);
  display: inline-block;
  transition: all 0.15s;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0.2rem 0.2rem 2.4rem rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
}
.button .text {
  display: inline-flex;
  align-items: baseline;
}
.button:hover {
  background-color: #0ae394;
  color: rgb(20, 12, 46);
  background-color: #ccc;
  color: black;
}
.button.regular {
  font-size: 1.25rem;
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
}
.button.regular .text {
  gap: 0.5rem;
}
.button.regular .text img.blank-icon {
  width: 1.25rem;
}
.button.small {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.1rem;
}
.button.small .text {
  gap: 0.5rem;
}
.button.small .text img.blank-icon {
  width: 1.25rem;
}/*# sourceMappingURL=Button.css.map */