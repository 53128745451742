.icon.media.dark {
  color: white;
}
.icon.media.xlarge {
  text-align: center; /* Desktop S */
}
.icon.media.xlarge img {
  width: 4rem;
}
.icon.media.xlarge .text {
  margin: 1rem 0;
  font-size: 1.5rem;
}
@media only screen and (min-width: 600px) {
  .icon.media.xlarge img {
    width: 6rem;
  }
}
@media only screen and (min-width: 800px) {
  .icon.media.xlarge img {
    width: 8rem;
  }
}
@media only screen and (min-width: 1200px) {
  .icon.media.xlarge img {
    width: 10vw;
  }
}
.icon.media.mini img {
  width: 2rem;
}/*# sourceMappingURL=Icon.css.map */