@import "./../../styles/colors";

// Common container
.tags-container{
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

// Tag
.tag{
    padding: 0.15rem 0.6rem;
    border-radius: 0.35rem;
    background-color: #eee;
    border: 1px solid #eee;
    
    &.selected{
       
    }
    &.unselected{
        
    }

    &.clickable{
        cursor: pointer;
        transition: all 0.15s;

        &:hover{
            opacity: 0.66;
        }
    }
}



// custom tags
.tag{
    &.fun:not(.unselected){
        color: white;
        background: linear-gradient(24deg, rgb(181, 102, 233) 0%, rgb(255, 122, 14) 50%, rgb(224, 235, 24) 100%);
    }
}