

.link{

    &.text-link{
        text-decoration: underline;
    }
    cursor: pointer;
    &:not(.button):hover{
        opacity: 0.66;
    }
}