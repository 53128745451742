.project-container .scrollable-outer-container {
  height: 100%;
  overflow-y: auto;
  padding: 0px;
}
.project-container .scrollable-outer-container .page-header-container {
  height: 700px;
}
.project-container .button.expand-image {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.project-container .main-body-container {
  margin: 0 1.5rem 8rem;
  transition: max-width 0.5s;
  min-height: 1200px;
}
@media only screen and (min-width: 800px) {
  .project-container .main-body-container {
    margin: 0 auto 12rem;
  }
}
.project-container .main-body-container .project-description {
  margin: 6rem auto 0;
}
.project-container .main-body-container .project-description a {
  text-decoration: underline;
}
.project-container .main-body-container .project-description a:hover {
  opacity: 0.5;
}
.project-container .main-body-container .project-description h1, .project-container .main-body-container .project-description h2, .project-container .main-body-container .project-description h3, .project-container .main-body-container .project-description h4, .project-container .main-body-container .project-description p, .project-container .main-body-container .project-description ul, .project-container .main-body-container .project-description .media-caption, .project-container .main-body-container .project-description .one-grid, .project-container .main-body-container .project-description .two-grid {
  max-width: 900px;
}
.project-container .main-body-container .project-description h1, .project-container .main-body-container .project-description h2, .project-container .main-body-container .project-description h3, .project-container .main-body-container .project-description h4, .project-container .main-body-container .project-description p, .project-container .main-body-container .project-description ul, .project-container .main-body-container .project-description img, .project-container .main-body-container .project-description video, .project-container .main-body-container .project-description .media-caption, .project-container .main-body-container .project-description .one-grid, .project-container .main-body-container .project-description .two-grid {
  margin: auto;
}
.project-container .main-body-container .project-description h1 {
  margin: 5rem auto 2.5rem;
}
.project-container .main-body-container .project-description h2 {
  margin: 4rem auto 2rem;
}
.project-container .main-body-container .project-description h3 {
  margin: 3rem auto 1.5rem;
}
.project-container .main-body-container .project-description h4 {
  margin: 2rem auto 1rem;
}
.project-container .main-body-container .project-description p {
  margin: 1rem auto 1rem;
}
.project-container .main-body-container .project-description ul {
  padding: 1rem 0 1rem 1.5rem;
}
.project-container .main-body-container .project-description ul li {
  padding: 0.25rem 0rem 0.25rem 0.25rem;
}
.project-container .main-body-container .project-description ul li h5 {
  margin: 2rem 0 0.5rem;
}
.project-container .main-body-container .project-description ul:not(.bullet-style) {
  list-style: none;
  padding: 0;
}
.project-container .main-body-container .project-description ul:not(.bullet-style) li {
  padding-left: 0;
}
.project-container .main-body-container .project-description .gif {
  text-align: center;
}
.project-container .main-body-container .project-description .gif img {
  max-width: 600px;
}
@media only screen and (min-width: 800px) {
  .project-container .main-body-container .project-description .gif img {
    max-width: 100%;
  }
}
.project-container .main-body-container .project-description .gif.full-width img {
  width: 100%;
  max-width: none !important;
}
.project-container .main-body-container .project-description img, .project-container .main-body-container .project-description video {
  max-width: 100%;
  display: block;
  margin: 2rem auto;
}
@media only screen and (min-width: 1200px) {
  .project-container .main-body-container .project-description img, .project-container .main-body-container .project-description video {
    max-width: 100%;
  }
}
.project-container .main-body-container .project-description img.img-shadow, .project-container .main-body-container .project-description video.img-shadow {
  box-shadow: 0.2rem 0.2rem 2.4rem rgba(0, 0, 0, 0.15);
}
.project-container .main-body-container .project-description .media-caption {
  color: #666;
  border-left: 1px solid #ccc;
  padding-left: 1rem;
}
.project-container .main-body-container .project-description .one-grid, .project-container .main-body-container .project-description .two-grid {
  margin: 2rem auto;
  display: grid;
}
.project-container .main-body-container .project-description .one-grid .card.medium-card, .project-container .main-body-container .project-description .two-grid .card.medium-card {
  padding: 1rem;
  margin: auto;
  text-decoration: none;
}
.project-container .main-body-container .project-description .one-grid .card.medium-card .title, .project-container .main-body-container .project-description .two-grid .card.medium-card .title {
  margin: 1rem 0;
}
.project-container .main-body-container .project-description .one-grid .card.medium-card .img-container, .project-container .main-body-container .project-description .two-grid .card.medium-card .img-container {
  height: 250px;
}
.project-container .main-body-container .project-description .one-grid .card.medium-card .img-container img, .project-container .main-body-container .project-description .two-grid .card.medium-card .img-container img {
  height: 250px;
  margin: auto;
}
.project-container .main-body-container .project-description .one-grid .card.medium-card .link.profile img, .project-container .main-body-container .project-description .two-grid .card.medium-card .link.profile img {
  margin: 0;
}
.project-container .main-body-container .project-description .one-grid {
  grid-template-columns: 1fr;
}
.project-container .main-body-container .project-description .one-grid .card.medium-card {
  max-width: 50%;
}
.project-container .main-body-container .project-description .two-grid {
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.project-container.shrank-img .main-body-container {
  max-width: 900px;
}
.project-container.shrank-img .main-body-container h4.media-title {
  margin-top: 3rem;
  margin-bottom: 0rem;
}
.project-container.shrank-img .main-body-container .media-caption {
  margin-top: 0rem;
  margin-bottom: 2rem;
}

.project-container.expanded-img .main-body-container {
  max-width: 2400px;
}
.project-container.expanded-img .main-body-container h4.media-title {
  margin-top: 6rem;
  margin-bottom: 2rem;
}
.project-container.expanded-img .main-body-container .media-caption {
  margin-top: 2rem;
  margin-bottom: 8rem;
}
@media only screen and (min-width: 800px) {
  .project-container.expanded-img .main-body-container {
    margin: 0 4rem 12rem;
  }
}

.accordion {
  border-bottom: 1px solid #ccc;
}
.accordion .collapsible-cta {
  cursor: pointer;
  margin: 2rem 0;
  text-align: center;
}
.accordion .collapsible-cta h5 {
  margin: 0.5rem 0;
}
.accordion .collapsible-cta .arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transition: all 0.5s;
}
.accordion .collapsible-cta .arrow.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.accordion .collapsible-cta .arrow.up {
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
}
.accordion .collapsible-cta:hover {
  opacity: 0.66;
}
.accordion .collapsible {
  transition: all 1s;
}
.accordion .collapsible.hidden {
  max-height: 0px;
  overflow: hidden;
}
.accordion .collapsible.shown {
  max-height: 10000px;
}

/*

    THEME DEFAULT

*/
.project-container .navigation-container {
  transition: background-color 0.5s;
  transition: color 0.5s;
}
.project-container .navigation-container.top {
  background-color: rgba(243, 168, 48, 0);
}
.project-container .navigation-container.top ul li {
  border-color: white;
}
.project-container .navigation-container.top ul li a {
  color: white;
}
.project-container .page-header-container {
  overflow-y: hidden;
  background-color: rgb(20, 12, 46);
}
.project-container .page-header-container .bg-img {
  position: relative;
}
.project-container .page-header-container .bg-img .page-header-inner-container {
  box-sizing: border-box;
  color: #fff;
  position: absolute;
  padding: 0 4rem;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.project-container .page-header-container .bg-img .page-header-inner-container h1, .project-container .page-header-container .bg-img .page-header-inner-container .description {
  width: auto;
  /* Desktop S */
}
@media only screen and (min-width: 600px) {
  .project-container .page-header-container .bg-img .page-header-inner-container h1, .project-container .page-header-container .bg-img .page-header-inner-container .description {
    width: 50%;
  }
}
.project-container .page-header-container .bg-img .page-header-inner-container .categories-container {
  margin: 2rem 0;
}
.project-container .page-header-container .bg-img .page-header-inner-container .categories-container .categories-type-container {
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.project-container .page-header-container .bg-img .page-header-inner-container .categories-container .categories-type-container.roles .tag {
  color: rgb(255, 255, 255);
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.25);
}
.project-container .page-header-container .bg-img .page-header-inner-container .categories-container .categories-type-container.skills .tag {
  color: #fff;
  background: rgba(74, 65, 48, 0.2);
}

.project-container .page-header-container.bg-img .bg-img {
  max-width: 1600px;
  height: 800px;
  margin: 0 auto;
  position: relative;
  background-size: contain !important;
  background-position: right !important;
}
@media only screen and (min-width: 800px) {
  .project-container .page-header-container.bg-img .bg-img {
    height: 720px;
  }
}

.project-container .page-header-container.edge, .project-container .page-header-container.edge-2, .project-container .page-header-container.cc {
  background-color: rgb(0, 0, 0);
}
.project-container .page-header-container.edge.bg-img .bg-img, .project-container .page-header-container.edge-2.bg-img .bg-img, .project-container .page-header-container.cc.bg-img .bg-img {
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  background-size: cover !important;
  background-position: -200px !important;
}
@media only screen and (min-width: 800px) {
  .project-container .page-header-container.edge.bg-img .bg-img, .project-container .page-header-container.edge-2.bg-img .bg-img, .project-container .page-header-container.cc.bg-img .bg-img {
    height: 100%;
  }
}
@media only screen and (min-width: 1200px) {
  .project-container .page-header-container.edge.bg-img .bg-img, .project-container .page-header-container.edge-2.bg-img .bg-img, .project-container .page-header-container.cc.bg-img .bg-img {
    background-position: center !important;
  }
}
.project-container .page-header-container.edge.bg-img .bg-img .page-header-inner-container, .project-container .page-header-container.edge-2.bg-img .bg-img .page-header-inner-container, .project-container .page-header-container.cc.bg-img .bg-img .page-header-inner-container {
  color: #fff;
}
.project-container .page-header-container.edge.bg-img .bg-img .page-header-inner-container .categories-container .categories-type-container.roles .tag, .project-container .page-header-container.edge-2.bg-img .bg-img .page-header-inner-container .categories-container .categories-type-container.roles .tag, .project-container .page-header-container.cc.bg-img .bg-img .page-header-inner-container .categories-container .categories-type-container.roles .tag {
  color: rgb(255, 255, 255);
  background: rgba(113, 123, 162, 0.5);
}
.project-container .page-header-container.edge.bg-img .bg-img .page-header-inner-container .categories-container .categories-type-container.skills .tag, .project-container .page-header-container.edge-2.bg-img .bg-img .page-header-inner-container .categories-container .categories-type-container.skills .tag, .project-container .page-header-container.cc.bg-img .bg-img .page-header-inner-container .categories-container .categories-type-container.skills .tag {
  color: #fff;
  background: rgba(113, 123, 162, 0.3);
}

/*

    EACH THEME

*/
.page-header-container.edge.bg-img .bg-img {
  background-size: 60% !important;
}

.page-header-container.qz {
  background-image: radial-gradient(circle at 10% 10%, #4A1A59, #2B3D71, #192D65);
}
.page-header-container.qz .bg-img .page-header-inner-container {
  color: #fff;
}
.page-header-container.qz .bg-img .page-header-inner-container .categories-container .categories-type-container.roles .tag {
  color: rgb(255, 255, 255);
  background: rgba(113, 123, 162, 0.5);
}
.page-header-container.qz .bg-img .page-header-inner-container .categories-container .categories-type-container.skills .tag {
  color: #fff;
  background: rgba(113, 123, 162, 0.3);
}

.project-container .navigation-container.cc.top ul li {
  border-color: yellow;
}
.project-container .navigation-container.cc.top ul li a {
  color: yellow;
}

.page-header-container.cc .bg-img .page-header-inner-container {
  color: yellow;
}
.page-header-container.cc .bg-img .page-header-inner-container h1 {
  display: inline;
  color: rgb(247, 246, 236);
  text-shadow: -3px 0px 3px yellow, 3px 0px 3px yellow, 6px 0px 6px yellow, -6px 0px 6px yellow;
}
.page-header-container.cc .bg-img .page-header-inner-container .categories-container .categories-type-container.roles .tag {
  color: rgb(0, 0, 0) !important;
  background: rgb(255, 255, 47) !important;
}
.page-header-container.cc .bg-img .page-header-inner-container .categories-container .categories-type-container.skills .tag {
  color: rgb(0, 0, 0) !important;
  background: rgba(255, 255, 47, 0.8) !important;
}

.page-header-container.mg {
  background: radial-gradient(2000px, rgb(41, 191, 214) 0%, rgb(21, 76, 187) 70%, rgb(22, 132, 222) 100%);
  background-size: 200% 120%;
}
.page-header-container.mg .bg-img .page-header-inner-container .categories-container .categories-type-container.roles .tag {
  color: rgb(255, 255, 255);
  background: rgba(183, 189, 213, 0.5);
}
.page-header-container.mg .bg-img .page-header-inner-container .categories-container .categories-type-container.skills .tag {
  color: #fff;
  background: rgba(113, 123, 162, 0.3);
}

.project-container .navigation-container.shopest ul li {
  border-color: rgb(78, 44, 15);
}
.project-container .navigation-container.shopest ul li a {
  color: rgb(78, 44, 15);
}

.page-header-container.shopest {
  background: #ffc993;
}
.page-header-container.shopest .bg-img .page-header-inner-container {
  color: rgb(78, 44, 15);
}
.page-header-container.shopest .bg-img .page-header-inner-container h1 {
  display: inline;
}
.page-header-container.shopest .bg-img .page-header-inner-container .categories .category {
  color: white;
  background-color: rgb(243, 73, 8);
}
@media only screen and (min-width: 800px) {
  .page-header-container.shopest .overlay {
    background-color: rgba(255, 201, 147, 0.6);
  }
}
.page-header-container.shopest .overlay {
  background-color: rgba(255, 201, 147, 0);
}/*# sourceMappingURL=Project.css.map */