@import "./../../styles/colors";
@import "./../../styles/responsive";

// Media Icon

.icon.media{
    &.dark{
        color: white;
    }



    // size
    &.xlarge{
        text-align: center;

        
        
        img{
            width: 4rem;
        }
        .text{
            margin: 1rem 0;
            font-size: 1.5rem;
        }
        // @include resp(desktop-xxs) {
        //     font-size: 7rem;
        //     line-height: 7rem;
        // }
        @include resp(desktop-xs) {
            img{
                width: 6rem;
            }
        }
        @include resp(desktop-s) {
            img{
                width: 8rem;
            }
        }
        @include resp(desktop-m) {
            img{
                width: 10vw;
            }
        }
    }
    &.regular{

    }
    &.small{

    }
    &.mini{
        img{
            width: 2rem;
        }
    }
}