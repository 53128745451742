.footer-container {
  width: 100%;
  background-color: rgb(20, 12, 46);
}
.footer-container * {
  color: white;
}
.footer-container .footer-content-container {
  padding: 4rem 0rem;
  margin: 0 4rem;
  text-align: center;
}
@media only screen and (min-width: 800px) {
  .footer-container .footer-content-container {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (min-width: 1600px) {
  .footer-container .footer-content-container {
    margin: auto;
    max-width: 1600px;
  }
}
.footer-container .footer-content-container .left-container.caption .h5 {
  font-family: "Gilroy";
}
.footer-container .footer-content-container .left-container.caption .subtitle {
  margin: 0.5rem 0 0 0;
  color: rgb(120, 115, 135);
}
.footer-container .footer-content-container .right-container.media {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 2rem;
}
@media only screen and (min-width: 800px) {
  .footer-container .footer-content-container .right-container.media {
    justify-content: flex-end;
  }
}/*# sourceMappingURL=Footer.css.map */