.page-header-container {
  width: 100%;
}
.page-header-container .bg-img {
  max-width: 1600px;
  margin: auto;
  position: relative;
  background-size: contain !important;
  background-position: right !important;
}
@media only screen and (min-width: 800px) {
  .page-header-container .bg-img {
    background-size: auto 80% !important;
    background-position: center !important;
  }
}
.page-header-container .bg-img .overlay {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}
.page-header-container .page-header-inner-container {
  padding: 3rem 2rem 0;
  /* Desktop S */
}
@media only screen and (min-width: 600px) {
  .page-header-container .page-header-inner-container {
    padding: 4rem 2rem 0;
  }
}
@media only screen and (min-width: 800px) {
  .page-header-container .page-header-inner-container {
    padding: 4rem 2rem 0;
  }
  .page-header-container .page-header-inner-container .left-container, .page-header-container .page-header-inner-container .right-container {
    width: 50%;
  }
}
@media only screen and (min-width: 1200px) {
  .page-header-container .page-header-inner-container {
    padding: 4rem 2rem 0;
  }
}
@media only screen and (min-width: 1400px) {
  .page-header-container .page-header-inner-container {
    padding: 4rem 2rem 0;
  }
}
@media only screen and (min-width: 1600px) {
  .page-header-container .page-header-inner-container {
    padding: 4rem 0 0;
  }
}
.page-header-container .page-header-inner-container .header {
  font-size: 5rem;
  line-height: 5rem;
}
@media only screen and (min-width: 1600px) {
  .page-header-container .page-header-inner-container .header {
    font-size: 5rem;
    line-height: 5rem;
  }
}/*# sourceMappingURL=PageHeader.css.map */