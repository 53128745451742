.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag {
  padding: 0.15rem 0.6rem;
  border-radius: 0.35rem;
  background-color: #eee;
  border: 1px solid #eee;
}
.tag.clickable {
  cursor: pointer;
  transition: all 0.15s;
}
.tag.clickable:hover {
  opacity: 0.66;
}

.tag.fun:not(.unselected) {
  color: white;
  background: linear-gradient(24deg, rgb(181, 102, 233) 0%, rgb(255, 122, 14) 50%, rgb(224, 235, 24) 100%);
}/*# sourceMappingURL=Tag.css.map */