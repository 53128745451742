
$color-white: #fff;

$color-background-color: #F5EDED;


$color-lightgray: #ccc;




$color-key-orange: rgb(243, 168, 48);
$color-neon-green: #0ae394;

$color-dark-navy: rgb(20, 12, 46); //#09032c;
$color-dark-navy-50: rgb(120, 115, 135); //#09032c;