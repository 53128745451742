@import "./../../styles/colors";
@import "./../../styles/style-params";
@import "./../../styles/responsive";


.project-container{
    .scrollable-outer-container{
        height: 100%;
        overflow-y: auto;
        padding: 0px;
        .page-header-container{
            height: 700px;
        }
    }

    .button.expand-image{
        position: fixed;
        bottom: 1rem;
        right: 1rem;
    }
}



.project-container{
    .main-body-container{
        margin: 0 1.5rem 8rem;
        @include resp(desktop-s) { 
            margin: 0 auto 12rem;
        }
        transition: max-width 0.5s;
        min-height: 1200px;
    
        .project-description{
            margin: 6rem auto 0;

            a{
               text-decoration: underline;
               &:hover{
                opacity: 0.5;
               }
            }

            h1, h2, h3, h4, p, ul, .media-caption, .one-grid, .two-grid{
                max-width: 900px;
            }
            h1, h2, h3, h4, p, ul, img, video, .media-caption, .one-grid, .two-grid{
                margin: auto;
            }
            h1{
                margin: 5rem auto 2.5rem;
            }
            h2{
                margin: 4rem auto 2rem;
            }
            h3{
                margin: 3rem auto 1.5rem;
            }
            h4{
                margin: 2rem auto 1rem;
            }

            p{
                margin: 1rem auto 1rem;
            }

            ul{
                padding: 1rem 0 1rem 1.5rem;
                li{
                    h5{margin: 2rem 0 0.5rem}
                    padding: 0.25rem 0rem 0.25rem 0.25rem;
                }

                &:not(.bullet-style){
                    list-style: none;
                    padding: 0;
                    li{
                        padding-left: 0;
                    }
                }
                
                
            }

            // Media
            .gif{
                text-align: center;
    
                img{
                    max-width: 600px;
                    @include resp(desktop-s) { max-width: 100%; }
                }
                &.full-width img{
                    width: 100%;
                    max-width: none!important;
                }
                
            }
    
            img, video{
                max-width: 100%;
                display:block;
                margin: 2rem auto;
                @include resp(desktop-m) { max-width: 100%; }
    
                &.img-shadow{
                    box-shadow: $shadow-default;
                }
            }

            .media-caption{
                color: #666;
                border-left: 1px solid #ccc;
                padding-left: 1rem;
            }


            .one-grid, .two-grid{
                margin: 2rem auto;
                display: grid;
                .card.medium-card{
                    padding: 1rem;
                    margin: auto;
                    text-decoration: none;

                    .title{
                        margin: 1rem 0;
                    }
                    .img-container{
                        height: 250px;
                        img{
                            height: 250px;
                            margin: auto;
                        }
                    }
                    
                    .link.profile{
                        img{
                            margin: 0;
                        }
                    }
                }
            }
            .one-grid{
                grid-template-columns: 1fr;
                .card.medium-card{
                    max-width: 50%;
                }
            }
            .two-grid{
                grid-template-columns: 1fr 1fr;
                gap: 1rem;
            }
        }

        

        

        

        

        
    }
}

// Image and video sizes
.project-container.shrank-img{
    .main-body-container{
        max-width: 900px;
        h4.media-title{
            margin-top: 3rem;
            margin-bottom: 0rem;
        }
        .media-caption{
            margin-top: 0rem;
            margin-bottom: 2rem;
        }
    }
}
.project-container.expanded-img{
    .main-body-container{
        max-width: 2400px;

        h4.media-title{
            margin-top: 6rem;
            margin-bottom: 2rem;
        }
        .media-caption{
            margin-top: 2rem;
            margin-bottom: 8rem;
        }

        @include resp(desktop-s) { 
            margin: 0 4rem 12rem;
        }
    }
}


// Accordion
.accordion{
    .collapsible-cta{
        cursor: pointer;
        margin: 2rem 0;
        h5{
            margin: 0.5rem 0;
        }
        .arrow{
            border: solid black;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;

            transition: all 0.5s;
            &.down{
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
            }
            &.up{
                transform: rotate(225deg);
                -webkit-transform: rotate(225deg);
            }
            
        }

        &:hover{
            opacity: 0.66;
        }

        text-align: center;
    }
    .collapsible{
        transition: all 1s;
        

        &.hidden{
            max-height: 0px;
            overflow: hidden;
        }
        &.shown{
            max-height: 10000px;
        }
    }

    border-bottom: 1px solid #ccc;
}







/*

    THEME DEFAULT

*/
.project-container{
    .navigation-container{
        transition: background-color 0.5s;
        transition: color 0.5s;
        &.top{
            background-color: rgba(243, 168, 48, 0);
            ul li{
                a{
                    color: white;
                }
                border-color: white;
            }
        }
        
        
    }
    
    
    .page-header-container{
        overflow-y: hidden;
        background-color: $color-dark-navy;

        .bg-img{
            position: relative;
            .page-header-inner-container{
                box-sizing: border-box;
                color: #fff;
                position: absolute;
                
                
                padding: 0 4rem;
                top: 50%;
                transform: translateY(-50%);

                width: 100%;
                h1, .description{
                    width: auto;
                    @include resp(desktop-xs) { 
                        width: 50%;
                    }
                }

                .categories-container{
                    margin: 2rem 0;
                    .categories-type-container{
                        margin: 1rem 0;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 0.5rem;

                        &.roles{
                            .tag{
                                color: rgb(255, 255, 255);
                                background: rgba(255, 255, 255, 0.15);
                                border-color:rgba(255, 255, 255, 0.25);
                                //border: 1px solid rgba(255, 255, 255, 0.5);
                            }
                        }
                        &.skills{
                            .tag{
                                color: #fff;
                                background: rgba(74, 65, 48, 0.2);
                            }
                        }
                    }
                    
                    
                }
            }
            
        }
    }
    
}
// Right Image
.project-container{
    .page-header-container{
        &.bg-img .bg-img{
            max-width: 1600px;
            
            @include resp(desktop-s) { 
                height: 720px;
            }
            height: 800px;

            margin: 0 auto;
            position: relative;
        
            -webkit-background-size: contain!important;
            -moz-background-size: contain!important;
            -o-background-size: contain!important;
            background-size: contain!important;
            background-position: right!important;
        }
    } 
}

// Center Image
.project-container{
    .page-header-container{
        &.edge, &.edge-2, &.cc{
            background-color: rgb(0, 0, 0);
            &.bg-img .bg-img{
                max-width: 100%;
                height: 100%;
                @include resp(desktop-s) { 
                    height: 100%;
                }
                margin: 0 auto;
                position: relative;

                -webkit-background-size: cover!important;
                -moz-background-size: cover!important;
                -o-background-size: cover!important;
                background-size: cover!important;
                background-position: -200px!important;
                @include resp(desktop-m) { 
                    background-position: center!important;
                }

                .page-header-inner-container{
                    color: #fff;
        
                    // Categories
                    .categories-container{
                        .categories-type-container{
                            &.roles{
                                .tag{
                                    color: rgb(255, 255, 255);
                                    background: rgba(113, 123, 162, 0.5);
                                }
                            }
                            &.skills{
                                .tag{
                                    color: #fff;
                                    background: rgba(113, 123, 162, 0.3);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
}


/*

    EACH THEME

*/

// EDGE
.page-header-container.edge{
    &.bg-img .bg-img{
        -webkit-background-size: 60%!important;
        -moz-background-size: 60%!important;
        -o-background-size: 60%!important;
        background-size: 60%!important;
    }
}

// EDGE 2
.page-header-container.edge-2{
    .bg-img{
        .page-header-inner-container{
        }
    }
}

// QUARTZ
.page-header-container.qz{

    background-image:
        radial-gradient(
            circle at 10% 10%,
            #4A1A59,
            #2B3D71,
            #192D65
        );

    .bg-img{
        .page-header-inner-container{
            color: #fff;
            // Categories
            .categories-container{
                .categories-type-container{
                    &.roles{
                        .tag{
                            color: rgb(255, 255, 255);
                            background: rgba(113, 123, 162, 0.5);
                        }
                    }
                    &.skills{
                        .tag{
                            color: #fff;
                            background: rgba(113, 123, 162, 0.3);
                        }
                    }
                }
            }
        }
    }
}



// Creative Coding
.project-container .navigation-container.cc.top ul li{
    a{
        color: yellow;
    }
    border-color: yellow;
}
.page-header-container.cc{
    .bg-img{
        .page-header-inner-container{
            h1{
                display: inline;

                color: rgb(247, 246, 236);
                text-shadow: 
                    -3px 0px 3px yellow,
                    3px 0px 3px yellow,
                    6px 0px 6px yellow,
                    -6px 0px 6px yellow;
            }
            color: yellow;

            // Categories
            .categories-container{
                .categories-type-container{
                    &.roles{
                        .tag{
                            color: rgb(0, 0, 0)!important;
                            background: rgb(255, 255, 47)!important;
                        }
                    }
                    &.skills{
                        .tag{
                            color: rgb(0, 0, 0)!important;
                            background: rgba(255, 255, 47, 0.8)!important;
                        }
                    }
                }
            }
        }
    }
}

// Mansion Global
.page-header-container.mg{
    background: radial-gradient(2000px, rgb(41, 191, 214) 0%, rgb(21, 76, 187) 70%, rgb(22, 132, 222) 100%);
    background-size: 200% 120%;
    .bg-img{
        .page-header-inner-container{
            // Categories
            .categories-container{
                .categories-type-container{
                    &.roles{
                        .tag{
                            color: rgb(255, 255, 255);
                            background: rgba(183, 189, 213, 0.5);
                        }
                    }
                    &.skills{
                        .tag{
                            color: #fff;
                            background: rgba(113, 123, 162, 0.3);
                        }
                    }
                }
            }
        }
    }
}


// SHOPEST
.project-container .navigation-container.shopest ul li{
    a{
        color: rgb(78, 44, 15);
    }
    border-color: rgb(78, 44, 15);
}
.page-header-container.shopest{
    background:#ffc993;
    .bg-img{
        .page-header-inner-container{
            h1{
                display: inline;
            }
            color: rgb(78, 44, 15);
            .categories{
                .category{
                    color: white;
                    background-color: rgba(243, 73, 8, 1);
                }
            }
        }
    }
    @include resp(desktop-s) {
        .overlay{
             background-color: rgba(255, 201, 147, 0.6);
        }
    }
    .overlay{
        background-color: rgba(255, 201, 147, 0);
   }
}