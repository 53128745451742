
@import "./../../../styles/style-params";
@import "./../../../styles/responsive";


.grid-outer-container{
    width: auto;
    margin: 2rem;
    box-sizing: content-box;
    min-height: 1200px;
}
// grid rule
.grids-container{
    display: grid;
    margin: auto;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    
    @include resp(desktop-xs) { 
        grid-template-columns: 1fr 1fr;
        column-gap: 2rem;
        row-gap: 2rem;
    }
    @include resp(desktop-s) { 
        grid-template-columns: 1fr 1fr;
        column-gap: 2.5rem;
        row-gap: 2.5rem;
    }
    @include resp(desktop-m) { 
        grid-template-columns: 1fr 1fr 1fr;

    }
    @include resp(desktop-l) { 
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 3rem;
        row-gap: 3rem;
    }

}

// cosmetic
.grids-container{
    

    .grid-card-container{
        background-color: white;
        box-shadow: $shadow-card-default;
        
        &.link{
            text-decoration: none;
        }

        .hero-img-container{
        
            width: 100%;
            height: 300px;
            transition: height 0.5s;
            overflow: hidden;

            .img-inner-container{
                width: 120%;
                top: 0rem;
                left: -10%;
                position: relative;
                img{
                    width: 100%;
                    position: absolute;
                    &.hero-img{
                        z-index: 1000;
                    }
                    &.hover-img{
                        z-index: 1001;
    
                        opacity: 0;
                        transition: opacity 0.5s;
                        
                    }
                }
            }
            
        }
        
        .card-content-container{
            padding: 1.5rem;

            .title{
                margin: 0;
            }
            // Tag     
            .tags-container{
                gap: 0.5rem;
                .tag{
                    font-size: 0.75rem;
                    line-height: 1.00rem;
                    padding: 0.25rem 0.4rem;
                    border-radius: 0.2rem;
                }
            }
        }



        // Hover
        transition: transform 0.5s;
        &:hover{
            transform: scale(1.05);
            .grid-card-container{
                .hero-img-container{
                    .img-inner-container{
                        width: 140%;
                        transition: all 3s linear;
                        left: -20%;
                        top: -2rem;
                        img{
                            &.hover-img{
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

    }
}