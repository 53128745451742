.grid-outer-container {
  width: auto;
  margin: 2rem;
  box-sizing: content-box;
  min-height: 1200px;
}

.grids-container {
  display: grid;
  margin: auto;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  /* Desktop S */
}
@media only screen and (min-width: 600px) {
  .grids-container {
    grid-template-columns: 1fr 1fr;
    -moz-column-gap: 2rem;
         column-gap: 2rem;
    row-gap: 2rem;
  }
}
@media only screen and (min-width: 800px) {
  .grids-container {
    grid-template-columns: 1fr 1fr;
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
    row-gap: 2.5rem;
  }
}
@media only screen and (min-width: 1200px) {
  .grids-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media only screen and (min-width: 1400px) {
  .grids-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -moz-column-gap: 3rem;
         column-gap: 3rem;
    row-gap: 3rem;
  }
}

.grids-container .grid-card-container {
  background-color: white;
  box-shadow: 0.2rem 0.2rem 6.4rem rgba(0, 0, 0, 0.25);
  transition: transform 0.5s;
}
.grids-container .grid-card-container.link {
  text-decoration: none;
}
.grids-container .grid-card-container .hero-img-container {
  width: 100%;
  height: 300px;
  transition: height 0.5s;
  overflow: hidden;
}
.grids-container .grid-card-container .hero-img-container .img-inner-container {
  width: 120%;
  top: 0rem;
  left: -10%;
  position: relative;
}
.grids-container .grid-card-container .hero-img-container .img-inner-container img {
  width: 100%;
  position: absolute;
}
.grids-container .grid-card-container .hero-img-container .img-inner-container img.hero-img {
  z-index: 1000;
}
.grids-container .grid-card-container .hero-img-container .img-inner-container img.hover-img {
  z-index: 1001;
  opacity: 0;
  transition: opacity 0.5s;
}
.grids-container .grid-card-container .card-content-container {
  padding: 1.5rem;
}
.grids-container .grid-card-container .card-content-container .title {
  margin: 0;
}
.grids-container .grid-card-container .card-content-container .tags-container {
  gap: 0.5rem;
}
.grids-container .grid-card-container .card-content-container .tags-container .tag {
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.25rem 0.4rem;
  border-radius: 0.2rem;
}
.grids-container .grid-card-container:hover {
  transform: scale(1.05);
}
.grids-container .grid-card-container:hover .grid-card-container .hero-img-container .img-inner-container {
  width: 140%;
  transition: all 3s linear;
  left: -20%;
  top: -2rem;
}
.grids-container .grid-card-container:hover .grid-card-container .hero-img-container .img-inner-container img.hover-img {
  opacity: 1;
}/*# sourceMappingURL=Grids.css.map */