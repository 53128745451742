@import "./../../styles/colors";


.navigation-container{
   
    width: 100%;
    margin: 0;
    background-color: $color-background-color;
    box-shadow: rgba(255, 255, 255, 1) 10 10 20;
    
    ul li{
        padding-bottom: 1rem;
    }

    .internal-stack{
        display: flex;
        list-style: none;
        column-gap: 2rem;
        padding: 0;
    }

    &.intro{
        top: -6rem;
        padding: 2rem 0;
        background-color: rgba(255, 255, 255, 0);
    }

    &.fixed-to-top{
        position: fixed;
        z-index: 5000;
        top: 0;
        left: 0;
        right: 0;

        padding: 2rem 2rem 0rem;
        color: black;

        transition: top 0.5s;
        transition-delay: 0.25s;

        ul li{
            &.selected{
                border-bottom: 2px solid #000;
            }
        }
    }

   
}