@import "./../../styles/colors";
@import "./../../styles/responsive";
@import "./../../styles/style-params";

.button{
    
    border: 1px solid rgba(255, 255, 255, 0);
    display: inline-block;
    transition: all 0.15s;
    .text{
        display: inline-flex;
        align-items: baseline;
    }
   
    
    text-decoration: none;
    cursor: pointer;

    // decolation
    box-shadow: $shadow-default;
    background-color: #ffffff;

    &:hover{
        background-color: $color-neon-green;
        color:$color-dark-navy;
        background-color: $color-lightgray;
        color: black;
    }

    &.regular{
        font-size: 1.25rem;
        padding: 1rem 1.5rem;
        border-radius: 0.25rem;
        .text{
            gap: 0.5rem;
            img.blank-icon{
                width: 1.25rem;
            }
        }
    }

    &.small{
        font-size: 1rem;
        padding: 0.5rem 1rem;
        border-radius: 0.1rem;
        .text{
            gap: 0.5rem;
            img.blank-icon{
                width: 1.25rem;
            }
        }
    }
}