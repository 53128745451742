@font-face {
  font-family: "Archivo";
  src: url("../fonts/ArchivoBlack-Regular.ttf");
}
@font-face {
  font-family: "Gilroy";
  font-weight: "regular";
  src: url("../fonts/Gilroy-Regular.otf");
}
@font-face {
  font-family: "Gilroy";
  font-weight: "bold";
  src: url("../fonts/Gilroy-Bold.otf");
}
body {
  margin: 0px;
  padding: 0px;
}

html {
  background-color: #F5EDED;
  font-family: "Inter", sans-serif;
  font-weight: "regular";
  font-size: 10px;
  line-height: 16px;
  /* Desktop S */
}
@media only screen and (min-width: 600px) {
  html {
    font-size: 11px;
    line-height: 17px;
  }
}
@media only screen and (min-width: 800px) {
  html {
    font-size: 12px;
    line-height: 18px;
  }
}
@media only screen and (min-width: 1200px) {
  html {
    font-size: 14px;
    line-height: 20px;
  }
}
@media only screen and (min-width: 1400px) {
  html {
    font-size: 16px;
    line-height: 24px;
  }
}

h1, h2, h3, h4, h5 {
  font-weight: 700;
}

h1 {
  font-family: "Gilroy";
  font-weight: 400;
  font-size: 3rem;
  line-height: 3.5rem;
}

h2, .h2 {
  font-size: 2.5rem;
  line-height: 3rem;
}

h3, .h3 {
  font-size: 2rem;
  line-height: 2.5rem;
}

h4, .h4 {
  font-size: 1.5rem;
  line-height: 2rem;
}

h5, .h5 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

p {
  font-size: 1rem;
  line-height: 1.75rem;
}

a {
  color: black;
  text-decoration: none;
}

.align-container {
  width: 100%;
  text-align: center;
}
.align-container.center {
  margin: auto;
}

.text-yellow {
  color: rgb(243, 168, 48);
}

.text-neon-green {
  color: #0ae394;
}

.text-red {
  color: rgb(246, 47, 20);
}

.text-blue {
  color: rgb(23, 80, 238);
}

/* Text Alignment */
.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}/*# sourceMappingURL=style.css.map */