@import "./../../styles/style-params";
@import "./../../styles/responsive";
@import "./../../styles/colors";

.page-top-container{

    width: 100%;
    height: 100%;
    .scrollable-outer-container{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        overflow-y: scroll;
        width: 100%;
    }


}


// Gradation

.page-top-container{
    
    .gradation-container{

        position: fixed;
        z-index: 0;
        top: 100%; // for animation
        max-width: 1800px;
        transition: all 0.5s;
        overflow: hidden;

        @include resp(desktop-xxs) { 
            left: 1.5rem;
            right: 1.5rem;
            bottom: 1.5rem;
        }
        @include resp(desktop-xs) { 
            left: 4rem;
            right: 4rem;
            bottom: 3rem;
        }
        @include resp(desktop-m) { 
            left: 8rem;
            right: 8rem;
            bottom: 6rem;
        }
        @include resp(desktop-xxl) { 
            left: 50%;
            width: 100%;
            transform: translateX(-50%);
            bottom: 8rem;
        }
        .bg{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            min-height: 800px;
            &.bg3{
                z-index: 3;
                right: 30%;
                top: -20%;
                width: 100%;
                height: 150%;
            }

            &.bg2{
                z-index: 2;
                left: -00%;
                bottom: -70%;
                width: 2400px;
                height: 2400px;
                min-height: 2400px;
            }

            &.bg1{
                z-index: 1;
               
            }
            
            
        }

        // Default Color
        .bg{
            &.bg3{
                background: radial-gradient(rgba(255, 252, 255, 0));
            }
            &.bg2{
                background: radial-gradient(rgba(255, 252, 255, 0));
            }
            &.bg1{
                background: linear-gradient(300deg, $color-dark-navy, #1d255e, #190c5e);
                //background: linear-gradient(300deg, #1f2925, #1f2925, #1f2925);
                //background: linear-gradient(300deg, #16D183, #faff9d, #fffba7, #ece5c4);
                //background: linear-gradient(90deg, #ff003c, #ff6600);
            }
        }
    }

    &.loaded{
        .gradation-container{
            top: 1rem;
            @include resp(desktop-xxs) { 
                top: 1.5rem;
            }
            @include resp(desktop-xs) { 
                top: 3rem;
            }
            @include resp(desktop-m) { 
                top: 6rem;
            }
            @include resp(desktop-l) { 
                top: 8rem;
            }
        }
    }

    
    &.focus-intro{
        .gradation-container{
            .bg{
                &.bg3{
                    background: radial-gradient(#b588efdd, #b588ef33, #7b61e400, #6186E400);
                }
                &.bg2{
                    background: radial-gradient(rgba(237, 156, 235, 0.65),#db52c900, #d261e400, #6186E400);
                }
                &.bg1{
                    background: linear-gradient(290deg, #CF2769, #9368C5);
                }
            }
        }
    }


    &.focus-grids{
        .gradation-container{
           top: 10%;
            left: 0;
            right: 0;
            bottom: 100%;
        }
    }

    &.focus-process-and-skills{
        .gradation-container{
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            max-width: 100%;
        }
    }

}




// Intro

.page-top-container.top.focus-intro{
    

    .scrollable-outer-container{
        
        .intro-container{
            margin: 3rem 4rem 0;
            color: white;
            .navigation-container.intro a{
                color: white;
            }

            display: flex;

            @include resp(desktop-xs) { 
                margin: 4rem 6rem 0;
            }
            @include resp(desktop-s) { 
                margin: 6rem 8rem 0;
                .left-container{
                    width: 90%;
                }
                .right-container{

                }
            }
            @include resp(desktop-m) { 
                margin: 8rem 14rem 0;
            }
            @include resp(desktop-l) { 
                max-width: 1200px;
                margin: 16rem 18rem 0;
            }
            @include resp(desktop-xl) {
                margin: 16rem auto 0;
            }


            .left-container, .right-container{

            }


            .left-container{
                .title{
                    font-size: 6rem;
                    line-height: 6rem;
                    @include resp(desktop-m) { 
                        font-size: 6vw;
                        line-height: 6vw;
                    }
                }
                .subtitle{
                    font-size: 2rem;
                    line-height: 2.5rem;
                }

            }
            
        }

    }
    

}




// Grid

.page-top-container.top{

    .scrollable-outer-container{

        .grids-container{
            transition: all 0.75s;
            margin-top: 12rem;

            .grid-card{
                .grid-card-container{
                    transition: box-shadow 0.5s;
                    .card-content-container{
                        transition: padding 0.5s;
                    }
                }
            }

        }
    }

    &.focus-intro{
        .scrollable-outer-container{

            .grids-container{
                @include resp(desktop-xxs) { 
                    width: 90%;
                }
                @include resp(desktop-xs) { 
                    width: 80%;
                }
                @include resp(desktop-s) { 
                    column-gap: 2rem;
                    row-gap: 2rem;
                }
                @include resp(desktop-m) {

                }
                @include resp(desktop-l) {
                    max-width: 1200px;
                    column-gap: 2rem;
                    row-gap: 2rem;
                }

                .grid-card-container{
                    box-shadow: $shadow-card-dimmed;
                    .hero-img-container{
                        height: 250px;
                    }
                    .card-content-container{
                        padding: 1rem;
                        h2.title{
                            font-size: 1.5rem;
                        }
                        p{
                            font-size: 0.75rem;
                            line-height: 1rem;
                        }
                        // Tag     
                        .tags-container{
                            gap: 0.3rem;
                            .tag{
                                font-size: 0.5rem;
                                line-height: 0.75rem;
                                padding: 0.25rem 0.4rem;
                                border-radius: 0.1rem;
                                font-size: 0.6rem;
                            }
                        }
                        
                        
                        
                    }
                }
    
            }
        }
    }
    &.focus-grids{
        .scrollable-outer-container{
            .grids-container{
                width: 100%;

                margin-top: 20rem;
                @include resp(desktop-l) {
                    max-width: 1600px;
                }
                
                .grid-card-container{
                    box-shadow: $shadow-card-default;
                }

            }
        }
    }
}




// Researches / Skills

.page-top-container.top{
    .scrollable-outer-container{
        .skills-section-container, .researches-section-container{
            margin: 9rem 2rem 18rem;
            
            @include resp(desktop-m) { 
                margin: 12rem 4rem 24rem;
            }
            @include resp(desktop-l) { 
                max-width: 1400px;
                margin: 16rem auto 0;
            }

            .title-container{
                .title, .subtitle{
                    display:inline-block;
                    background-color: #0ae394;
                    color: #09032c;
                }


                .title{ 
                    font-size: 5rem;
                    line-height: 5rem;
                    padding: 2rem 2rem 1rem;
                    margin: 0;
                }
                .subtitle{
                    font-size: 2rem;
                    line-height: 2.5rem;
                    padding: 1rem 2rem 2rem;
                }
                @include resp(desktop-m) { 
                    .title{ 
                        font-size: 6vw;
                        line-height: 6vw;
                        padding: 2rem 2rem 1rem;
                        margin: 0;
                    }
                    .subtitle{
                        font-size: 2vw;
                        line-height: 3.5vw;
                        padding: 1rem 2rem 2rem;
                    }
                }
            }   
        }
    }
}


// Skills

.page-top-container{
    .scrollable-outer-container{
        .skills-section-container{
            color: rgb(200, 200, 224);
            a{
                color: rgb(200, 200, 224);
            }
            .skills-segments-container{
                margin-bottom: 24rem;
                .skills-by-type{
                    padding: 1rem 0rem;
                    margin: 2rem 0rem 0;
                    @include resp(desktop-m) { 
                        padding: 2rem;
                        margin: 2rem 4rem 0;
                    }
                    .process-explainer-container{
                        margin: 0rem 0 2rem;
                        .process-title{
                            font-size: 4vw;
                            line-height: 4.5vw;
                            margin: 1rem 0;
                        }
                        .process-description{   
                            margin: 2rem 0;  
                            max-width: 800px;
                            b{
                                color: white;
                            }
                        }
                        .process-description, .process-description p{
                            
                            font-size: 1.5rem;
                            line-height: 2.5rem;
                        }
                    }
                    

                    .skills{
                        display: flex;
                        flex-wrap: wrap;
                        column-gap: 1rem;
                        .skill{
                            img{
                                width: 6rem;
                            }
                        }
                    }


                    @include resp(desktop-xs) { 

                    }
                    @include resp(desktop-s) { 
                        &.indent-1{
                            margin-left: 4rem;
                        }
                        &.indent-2{
                            margin-left: 6rem;
                        }
                        &.indent-3{
                            margin-left: 8rem;
                        }
                        &.indent-4{
                            margin-left: 10rem;
                        }
                        // &.indent-5{
                        //     margin-left: 12rem;
                        // }
                        .process-explainer-container{
                            .process-title{
                            }
                            .process-description{   
                                max-width: 1200px;
                            }
                        }
                    }
                    @include resp(desktop-m) { 
                        
                    }
                    @include resp(desktop-l) { 
                        &.indent-1{
                            margin-left: 8rem;
                        }
                        &.indent-2{
                            margin-left: 12rem;
                        }
                        &.indent-3{
                            margin-left: 16rem;
                        }
                        &.indent-4{
                            margin-left: 20rem;
                        }
                        // &.indent-5{
                        //     margin-left: 24rem;
                        // }

                        .process-explainer-container{
                            .process-title{
                                font-size: 4rem;
                                line-height: 6rem;
                            }
                        }
                    }
                    @include resp(desktop-xl) {

                    }

                    
                    
                }
            }
        }

    } 

}






// Researches

.page-top-container .scrollable-outer-container .researches-section-container{
    padding-bottom: 8rem;
    .researches-segments-container{
        margin: 4rem 0;
        display: grid;
        gap: 2rem;
        grid-template-columns: 1fr;
        @include resp(desktop-m) { 
            grid-template-columns: 1fr 1fr 1fr;
        }
        @include resp(desktop-l) { 
        }
        @include resp(desktop-xl) {
            
        }

        .card.medium-card{
            .img-container{
                img{
                    width: 160%;
                    margin: auto;
                }
                
            }
            
        }
    }

}