.page-top-container.contact .page-header-container .bg-img .overlay {
  display: none;
}
.page-top-container.contact .contact-methods-container {
  max-width: 1200px;
  margin: 6rem 2rem 12rem;
}
@media only screen and (min-width: 1200px) {
  .page-top-container.contact .contact-methods-container {
    width: 100%;
    margin: 6rem auto 12rem;
  }
}
.page-top-container.contact .contact-methods-container .main-text {
  color: rgb(64, 47, 47);
}
.page-top-container.contact .contact-methods-container .main-text h1 {
  text-align: center;
  /* Desktop S */
  /* Desktop S */
}
@media only screen and (max-width: 599px) {
  .page-top-container.contact .contact-methods-container .main-text h1 {
    font-size: 7rem;
    line-height: 7rem;
  }
}
@media only screen and (min-width: 600px) {
  .page-top-container.contact .contact-methods-container .main-text h1 {
    font-size: 9rem;
    line-height: 9rem;
  }
}
@media only screen and (min-width: 800px) {
  .page-top-container.contact .contact-methods-container .main-text h1 {
    font-size: 14rem;
    line-height: 14rem;
  }
}
@media only screen and (min-width: 1200px) {
  .page-top-container.contact .contact-methods-container .main-text h1 {
    font-size: 12vw;
    line-height: 12.5vw;
  }
}
.page-top-container.contact .contact-methods-container .media-container {
  margin-top: 10vw;
  display: flex;
  justify-content: space-between;
}/*# sourceMappingURL=Contact.css.map */