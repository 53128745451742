@import "./../../styles/colors";
@import "./../../styles/responsive";

.page-top-container.contact{


    .page-header-container{
        .bg-img{
            .overlay{
                display: none;
            }
        }
    }

    .contact-methods-container{
        
        max-width: 1200px;
        margin: 6rem 2rem 12rem;
        @include resp(desktop-m) {
            width: 100%;
            margin: 6rem auto 12rem;
        }
        .main-text{
            color: rgb(64, 47, 47);
            
            h1{
                text-align: center;
                @include resp(desktop-xxs) {
                    font-size: 7rem;
                    line-height: 7rem;
                }
                @include resp(desktop-xs) {
                    font-size: 9rem;
                    line-height: 9rem;
                }
                @include resp(desktop-s) {
                    font-size: 14rem;
                    line-height: 14rem;
                }
                @include resp(desktop-m) {
                    font-size: 12vw;
                    line-height: 12.5vw;
                }
            }
        }
        .media-container{
            margin-top: 10vw;
            display: flex;
            justify-content: space-between;
        }
    }
}

