$xsmall-desktop: 600px;
$small-desktop: 800px;
$medium-desktop: 1200px;
$large-desktop: 1400px;
$xlarge-desktop: 1600px;
$xxlarge-desktop: 2000px;

@mixin resp($media){

    @if $media == desktop-xxs {
        /* Desktop S */
        @media only screen and (max-width: 599px) { @content; }
    }
    @if $media == desktop-xs {
        /* Desktop S */
        @media only screen and (min-width: $xsmall-desktop) { @content; }
    }
    @if $media == desktop-s {
        @media only screen and (min-width: $small-desktop) { @content; }
    }
    @if $media == desktop-m {
        @media only screen and (min-width: $medium-desktop) { @content; }
    }
    @if $media == desktop-l {
        @media only screen and (min-width: $large-desktop) { @content; }
    }
    @if $media == desktop-xl {
        @media only screen and (min-width: $xlarge-desktop) { @content; }
    }
    @if $media == desktop-xxl {
        @media only screen and (min-width: $xxlarge-desktop) { @content; }
    }
}