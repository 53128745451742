.page-top-container {
  width: 100%;
  height: 100%;
}
.page-top-container .scrollable-outer-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  overflow-y: scroll;
  width: 100%;
}

.page-top-container .gradation-container {
  position: fixed;
  z-index: 0;
  top: 100%;
  max-width: 1800px;
  transition: all 0.5s;
  overflow: hidden;
  /* Desktop S */
  /* Desktop S */
}
@media only screen and (max-width: 599px) {
  .page-top-container .gradation-container {
    left: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
  }
}
@media only screen and (min-width: 600px) {
  .page-top-container .gradation-container {
    left: 4rem;
    right: 4rem;
    bottom: 3rem;
  }
}
@media only screen and (min-width: 1200px) {
  .page-top-container .gradation-container {
    left: 8rem;
    right: 8rem;
    bottom: 6rem;
  }
}
@media only screen and (min-width: 2000px) {
  .page-top-container .gradation-container {
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    bottom: 8rem;
  }
}
.page-top-container .gradation-container .bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 800px;
}
.page-top-container .gradation-container .bg.bg3 {
  z-index: 3;
  right: 30%;
  top: -20%;
  width: 100%;
  height: 150%;
}
.page-top-container .gradation-container .bg.bg2 {
  z-index: 2;
  left: 0%;
  bottom: -70%;
  width: 2400px;
  height: 2400px;
  min-height: 2400px;
}
.page-top-container .gradation-container .bg.bg1 {
  z-index: 1;
}
.page-top-container .gradation-container .bg.bg3 {
  background: radial-gradient(rgba(255, 252, 255, 0));
}
.page-top-container .gradation-container .bg.bg2 {
  background: radial-gradient(rgba(255, 252, 255, 0));
}
.page-top-container .gradation-container .bg.bg1 {
  background: linear-gradient(300deg, rgb(20, 12, 46), #1d255e, #190c5e);
}
.page-top-container.loaded .gradation-container {
  top: 1rem;
  /* Desktop S */
  /* Desktop S */
}
@media only screen and (max-width: 599px) {
  .page-top-container.loaded .gradation-container {
    top: 1.5rem;
  }
}
@media only screen and (min-width: 600px) {
  .page-top-container.loaded .gradation-container {
    top: 3rem;
  }
}
@media only screen and (min-width: 1200px) {
  .page-top-container.loaded .gradation-container {
    top: 6rem;
  }
}
@media only screen and (min-width: 1400px) {
  .page-top-container.loaded .gradation-container {
    top: 8rem;
  }
}
.page-top-container.focus-intro .gradation-container .bg.bg3 {
  background: radial-gradient(rgba(181, 136, 239, 0.8666666667), rgba(181, 136, 239, 0.2), rgba(123, 97, 228, 0), rgba(97, 134, 228, 0));
}
.page-top-container.focus-intro .gradation-container .bg.bg2 {
  background: radial-gradient(rgba(237, 156, 235, 0.65), rgba(219, 82, 201, 0), rgba(210, 97, 228, 0), rgba(97, 134, 228, 0));
}
.page-top-container.focus-intro .gradation-container .bg.bg1 {
  background: linear-gradient(290deg, #CF2769, #9368C5);
}
.page-top-container.focus-grids .gradation-container {
  top: 10%;
  left: 0;
  right: 0;
  bottom: 100%;
}
.page-top-container.focus-process-and-skills .gradation-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
}

.page-top-container.top.focus-intro .scrollable-outer-container .intro-container {
  margin: 3rem 4rem 0;
  color: white;
  display: flex;
  /* Desktop S */
}
.page-top-container.top.focus-intro .scrollable-outer-container .intro-container .navigation-container.intro a {
  color: white;
}
@media only screen and (min-width: 600px) {
  .page-top-container.top.focus-intro .scrollable-outer-container .intro-container {
    margin: 4rem 6rem 0;
  }
}
@media only screen and (min-width: 800px) {
  .page-top-container.top.focus-intro .scrollable-outer-container .intro-container {
    margin: 6rem 8rem 0;
  }
  .page-top-container.top.focus-intro .scrollable-outer-container .intro-container .left-container {
    width: 90%;
  }
}
@media only screen and (min-width: 1200px) {
  .page-top-container.top.focus-intro .scrollable-outer-container .intro-container {
    margin: 8rem 14rem 0;
  }
}
@media only screen and (min-width: 1400px) {
  .page-top-container.top.focus-intro .scrollable-outer-container .intro-container {
    max-width: 1200px;
    margin: 16rem 18rem 0;
  }
}
@media only screen and (min-width: 1600px) {
  .page-top-container.top.focus-intro .scrollable-outer-container .intro-container {
    margin: 16rem auto 0;
  }
}
.page-top-container.top.focus-intro .scrollable-outer-container .intro-container .left-container .title {
  font-size: 6rem;
  line-height: 6rem;
}
@media only screen and (min-width: 1200px) {
  .page-top-container.top.focus-intro .scrollable-outer-container .intro-container .left-container .title {
    font-size: 6vw;
    line-height: 6vw;
  }
}
.page-top-container.top.focus-intro .scrollable-outer-container .intro-container .left-container .subtitle {
  font-size: 2rem;
  line-height: 2.5rem;
}

.page-top-container.top .scrollable-outer-container .grids-container {
  transition: all 0.75s;
  margin-top: 12rem;
}
.page-top-container.top .scrollable-outer-container .grids-container .grid-card .grid-card-container {
  transition: box-shadow 0.5s;
}
.page-top-container.top .scrollable-outer-container .grids-container .grid-card .grid-card-container .card-content-container {
  transition: padding 0.5s;
}
.page-top-container.top.focus-intro .scrollable-outer-container .grids-container {
  /* Desktop S */
  /* Desktop S */
}
@media only screen and (max-width: 599px) {
  .page-top-container.top.focus-intro .scrollable-outer-container .grids-container {
    width: 90%;
  }
}
@media only screen and (min-width: 600px) {
  .page-top-container.top.focus-intro .scrollable-outer-container .grids-container {
    width: 80%;
  }
}
@media only screen and (min-width: 800px) {
  .page-top-container.top.focus-intro .scrollable-outer-container .grids-container {
    -moz-column-gap: 2rem;
         column-gap: 2rem;
    row-gap: 2rem;
  }
}
@media only screen and (min-width: 1400px) {
  .page-top-container.top.focus-intro .scrollable-outer-container .grids-container {
    max-width: 1200px;
    -moz-column-gap: 2rem;
         column-gap: 2rem;
    row-gap: 2rem;
  }
}
.page-top-container.top.focus-intro .scrollable-outer-container .grids-container .grid-card-container {
  box-shadow: 0.2rem 0.2rem 3.2rem rgba(0, 0, 0, 0.02);
}
.page-top-container.top.focus-intro .scrollable-outer-container .grids-container .grid-card-container .hero-img-container {
  height: 250px;
}
.page-top-container.top.focus-intro .scrollable-outer-container .grids-container .grid-card-container .card-content-container {
  padding: 1rem;
}
.page-top-container.top.focus-intro .scrollable-outer-container .grids-container .grid-card-container .card-content-container h2.title {
  font-size: 1.5rem;
}
.page-top-container.top.focus-intro .scrollable-outer-container .grids-container .grid-card-container .card-content-container p {
  font-size: 0.75rem;
  line-height: 1rem;
}
.page-top-container.top.focus-intro .scrollable-outer-container .grids-container .grid-card-container .card-content-container .tags-container {
  gap: 0.3rem;
}
.page-top-container.top.focus-intro .scrollable-outer-container .grids-container .grid-card-container .card-content-container .tags-container .tag {
  font-size: 0.5rem;
  line-height: 0.75rem;
  padding: 0.25rem 0.4rem;
  border-radius: 0.1rem;
  font-size: 0.6rem;
}
.page-top-container.top.focus-grids .scrollable-outer-container .grids-container {
  width: 100%;
  margin-top: 20rem;
}
@media only screen and (min-width: 1400px) {
  .page-top-container.top.focus-grids .scrollable-outer-container .grids-container {
    max-width: 1600px;
  }
}
.page-top-container.top.focus-grids .scrollable-outer-container .grids-container .grid-card-container {
  box-shadow: 0.2rem 0.2rem 6.4rem rgba(0, 0, 0, 0.25);
}

.page-top-container.top .scrollable-outer-container .skills-section-container, .page-top-container.top .scrollable-outer-container .researches-section-container {
  margin: 9rem 2rem 18rem;
}
@media only screen and (min-width: 1200px) {
  .page-top-container.top .scrollable-outer-container .skills-section-container, .page-top-container.top .scrollable-outer-container .researches-section-container {
    margin: 12rem 4rem 24rem;
  }
}
@media only screen and (min-width: 1400px) {
  .page-top-container.top .scrollable-outer-container .skills-section-container, .page-top-container.top .scrollable-outer-container .researches-section-container {
    max-width: 1400px;
    margin: 16rem auto 0;
  }
}
.page-top-container.top .scrollable-outer-container .skills-section-container .title-container .title, .page-top-container.top .scrollable-outer-container .skills-section-container .title-container .subtitle, .page-top-container.top .scrollable-outer-container .researches-section-container .title-container .title, .page-top-container.top .scrollable-outer-container .researches-section-container .title-container .subtitle {
  display: inline-block;
  background-color: #0ae394;
  color: #09032c;
}
.page-top-container.top .scrollable-outer-container .skills-section-container .title-container .title, .page-top-container.top .scrollable-outer-container .researches-section-container .title-container .title {
  font-size: 5rem;
  line-height: 5rem;
  padding: 2rem 2rem 1rem;
  margin: 0;
}
.page-top-container.top .scrollable-outer-container .skills-section-container .title-container .subtitle, .page-top-container.top .scrollable-outer-container .researches-section-container .title-container .subtitle {
  font-size: 2rem;
  line-height: 2.5rem;
  padding: 1rem 2rem 2rem;
}
@media only screen and (min-width: 1200px) {
  .page-top-container.top .scrollable-outer-container .skills-section-container .title-container .title, .page-top-container.top .scrollable-outer-container .researches-section-container .title-container .title {
    font-size: 6vw;
    line-height: 6vw;
    padding: 2rem 2rem 1rem;
    margin: 0;
  }
  .page-top-container.top .scrollable-outer-container .skills-section-container .title-container .subtitle, .page-top-container.top .scrollable-outer-container .researches-section-container .title-container .subtitle {
    font-size: 2vw;
    line-height: 3.5vw;
    padding: 1rem 2rem 2rem;
  }
}

.page-top-container .scrollable-outer-container .skills-section-container {
  color: rgb(200, 200, 224);
}
.page-top-container .scrollable-outer-container .skills-section-container a {
  color: rgb(200, 200, 224);
}
.page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container {
  margin-bottom: 24rem;
}
.page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type {
  padding: 1rem 0rem;
  margin: 2rem 0rem 0;
  /* Desktop S */
}
@media only screen and (min-width: 1200px) {
  .page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type {
    padding: 2rem;
    margin: 2rem 4rem 0;
  }
}
.page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type .process-explainer-container {
  margin: 0rem 0 2rem;
}
.page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type .process-explainer-container .process-title {
  font-size: 4vw;
  line-height: 4.5vw;
  margin: 1rem 0;
}
.page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type .process-explainer-container .process-description {
  margin: 2rem 0;
  max-width: 800px;
}
.page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type .process-explainer-container .process-description b {
  color: white;
}
.page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type .process-explainer-container .process-description, .page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type .process-explainer-container .process-description p {
  font-size: 1.5rem;
  line-height: 2.5rem;
}
.page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type .skills {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type .skills .skill img {
  width: 6rem;
}
@media only screen and (min-width: 800px) {
  .page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type.indent-1 {
    margin-left: 4rem;
  }
  .page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type.indent-2 {
    margin-left: 6rem;
  }
  .page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type.indent-3 {
    margin-left: 8rem;
  }
  .page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type.indent-4 {
    margin-left: 10rem;
  }
  .page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type .process-explainer-container .process-description {
    max-width: 1200px;
  }
}
@media only screen and (min-width: 1400px) {
  .page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type.indent-1 {
    margin-left: 8rem;
  }
  .page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type.indent-2 {
    margin-left: 12rem;
  }
  .page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type.indent-3 {
    margin-left: 16rem;
  }
  .page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type.indent-4 {
    margin-left: 20rem;
  }
  .page-top-container .scrollable-outer-container .skills-section-container .skills-segments-container .skills-by-type .process-explainer-container .process-title {
    font-size: 4rem;
    line-height: 6rem;
  }
}
.page-top-container .scrollable-outer-container .researches-section-container {
  padding-bottom: 8rem;
}
.page-top-container .scrollable-outer-container .researches-section-container .researches-segments-container {
  margin: 4rem 0;
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
}
@media only screen and (min-width: 1200px) {
  .page-top-container .scrollable-outer-container .researches-section-container .researches-segments-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.page-top-container .scrollable-outer-container .researches-section-container .researches-segments-container .card.medium-card .img-container img {
  width: 160%;
  margin: auto;
}/*# sourceMappingURL=Top.css.map */