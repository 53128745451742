.navigation-container {
  width: 100%;
  margin: 0;
  background-color: #F5EDED;
  box-shadow: rgb(255, 255, 255) 10 10 20;
}
.navigation-container ul li {
  padding-bottom: 1rem;
}
.navigation-container .internal-stack {
  display: flex;
  list-style: none;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  padding: 0;
}
.navigation-container.intro {
  top: -6rem;
  padding: 2rem 0;
  background-color: rgba(255, 255, 255, 0);
}
.navigation-container.fixed-to-top {
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  right: 0;
  padding: 2rem 2rem 0rem;
  color: black;
  transition: top 0.5s;
  transition-delay: 0.25s;
}
.navigation-container.fixed-to-top ul li.selected {
  border-bottom: 2px solid #000;
}/*# sourceMappingURL=Navigation.css.map */