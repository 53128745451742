@import "./../../styles/style-params";
@import "./../../styles/responsive";



// Card
.card{
    .title, .description{
        text-decoration: none;
    }
}


// Medium Card
.card.medium-card{

   
    background-color: white;
    box-shadow: $shadow-card-dimmed;
    box-sizing: content-box;

    padding: 1.5rem 1.5rem 3rem;
    @include resp(desktop-m) { 
        padding: 2rem 2rem 4rem;
    }

    .img-container{
        width: 100%;
        height: 200px;
        position: relative;
        overflow: hidden;
        
        img{
            position: absolute;
            top: -9999px;
            bottom: -9999px;
            left: -9999px;
            right: -9999px;
            margin: auto;
        }
    }
    
    .title{
        margin: 1rem 0;
    }
    .description{
        
    }

    position: relative;
    .profile{
        display: flex;
        align-items: center;
        gap: 0.5rem;
        position: absolute;
        bottom: 2rem;

        color: #999;
        font-size: 1rem;

        img{
            width: 1.5rem;
            border-radius: 50%;
        }
    }
}