.page-top-container.all-projects .page-header-container .bg-img .overlay {
  display: none;
}
.page-top-container.all-projects .page-header-container .tags-container .tag:not(.selected) {
  color: rgb(115, 110, 105);
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(152, 140, 129, 0.5);
}
.page-top-container.all-projects .page-header-container .tags-container .tag.selected {
  background-color: rgb(115, 110, 105);
  border-color: rgba(0, 0, 0, 0);
  color: #fff;
}
.page-top-container.all-projects .grids-container {
  margin-top: 4rem;
  max-width: 1600px;
}/*# sourceMappingURL=AllProjects.css.map */