.card .title, .card .description {
  text-decoration: none;
}

.card.medium-card {
  background-color: white;
  box-shadow: 0.2rem 0.2rem 3.2rem rgba(0, 0, 0, 0.02);
  box-sizing: content-box;
  padding: 1.5rem 1.5rem 3rem;
  position: relative;
}
@media only screen and (min-width: 1200px) {
  .card.medium-card {
    padding: 2rem 2rem 4rem;
  }
}
.card.medium-card .img-container {
  width: 100%;
  height: 200px;
  position: relative;
  overflow: hidden;
}
.card.medium-card .img-container img {
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}
.card.medium-card .title {
  margin: 1rem 0;
}
.card.medium-card .profile {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: absolute;
  bottom: 2rem;
  color: #999;
  font-size: 1rem;
}
.card.medium-card .profile img {
  width: 1.5rem;
  border-radius: 50%;
}/*# sourceMappingURL=Card.css.map */